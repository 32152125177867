<!-- Modal -->

<div class="modal-content" *ngIf="modalType == 'contact-us'">
    <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Contact us Messages</h5>
        <button type="button" class="close">
            <i class="fa fa-times text-color" aria-hidden="true" (click)="closeModal()"></i>
        </button>
    </div>
    <div class="modal-body">
        <h6><strong class="pr-2">Name:</strong> {{modalData.name}}</h6>
        <p class="text-break"><strong class="pr-2">Email:</strong> {{modalData.email}}</p>
        <p class="text-break"><strong class="pr-2">Phone:</strong> {{modalData.phone}}</p>
        <p class="text-break"><strong class="pr-2">Date:</strong> {{modalData.modify_date | date : 'MMM dd, yy, h:mm a'
            }}</p>
        <p class="text-break"><strong class="pr-2">Description:</strong> {{modalData.user_message}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="closeModal()">OK</button>
    </div>
</div>

<div class="modal-content" *ngIf="modalType == 'notification'">
    <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel">Notification</h6>
        <button type="button" class="close">
            <i class="fa fa-times text-color" aria-hidden="true" (click)="closeModal()"></i>
        </button>
    </div>
    <div class="modal-body">
        <h6><strong class="pr-2">Title:</strong> {{modalData.title}}</h6>
        <p class="text-break"><strong class="pr-2">Date:</strong> {{modalData.create_date | date : 'MMM dd, yy, h:mm a'
            }}</p>
        <p class="text-break"><strong class="pr-2">Message:</strong> {{modalData.message}}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-warning" (click)="closeModal()">OK</button>
    </div>
</div>