export const AppUserCred = {
    username: 'appuser@gmail.com',
    // password: 'AppUser@123$',
    // client_id: '3OgO0IgPCA8VUhKfYt4pmtvmiaeUHgG3UBhkA86t',
    // client_secret: 'dRDg2B65QKTRlaT3hjoRdnWHky36SDYZlI3nZVdUMt1IJUcWpXNnlnrimUafkojlCOYkSjmumAfGDQvaZfnsM2BW8ZqmVKzaQZgpyhQpKeCgAw5SkMMx7ZrrCiphHZMw',
    password: 'AppUser@123$',
    client_id: 'TeZb971sK5mLhHZa7QqCpwF7VN7FbNqrj4Zw4Y21',
    client_secret: 'pbkdf2_sha256$216000$M2dfoKy5vCAi$D2azZ3ycxH49qi76XdZQhB8LQCAlyRQB6Q+U42nHhxc=',
    grant_type: 'password'
};
