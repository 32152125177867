import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AppUserCred } from './../helpers/app-user-cred';
import { fromEvent, BehaviorSubject, Observable, Subject } from 'rxjs';
import { IContactUs } from '../helpers/contactus';



@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private isloggedIn: boolean;
    private isRemember = false;
    private isExpired = false;
    private decodedToken: any;
    private expirationDate: Date;
    public userInactive$: Subject<any> = new Subject();
    private isOnline: boolean;
    private siteKey = '6LdRcD8UAAAAAPTe5gDKnTdoKgdAbH_m7rDtDMBO';
    public updatedUserProfile: BehaviorSubject<any> = new BehaviorSubject<boolean>(null);



    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.isOnline = window.navigator.onLine;

        fromEvent(window, 'online').subscribe(e => {
            this.isOnline = true;
        });

        fromEvent(window, 'offline').subscribe(e => {
            this.isOnline = false;
        });
    }

    isRememberMe(value: boolean): any {
        this.isRemember = value;
    }

    getRememberMe(): boolean {
        return this.isRemember;
    }

    setAccessToken(): any {
        return this.http.post<any>(`${environment.apiUrl}/o/token/`, AppUserCred)
            .pipe(map(token => {
                return token;
            }));
    }

    login(data) {
        return this.http.post<any>(`${environment.apiUrl}/accounts/log_in/`, data)
            .pipe(map(user => {
                return user;
            }));
    }


    register(user: any) {
        return this.http.post(`${environment.apiUrl}/accounts/create_account/`, user)
            .pipe(map(res => {
                return res;
            }));
    }

    fotgetPassword(data) {
        return this.http.post(`${environment.apiUrl}/accounts/forgot_password/`, data)
            .pipe(map(res => {
                return res;
            }));
    }

    logout(user) {
        return this.http.patch(`${environment.apiUrl}/accounts/change_online_status/${user.id}/?status=False`, {})
            .pipe(map(res => {
                return res;
            }));
    }

    logedIn(user) {
        return this.http.patch(`${environment.apiUrl}/accounts/change_online_status/${user.id}/?status=True`, {})
            .pipe(map(res => {
                return res;
            }));
    }

    getToken(): string {
        const token = JSON.parse(localStorage.getItem('pub-token'));
        return token;
    }

    getUser(): string {
        const token = JSON.parse(localStorage.getItem('auth-token'));
        return token;
    }

    getSiteKey(): string {
        return this.siteKey;
    }


    updateUserProfile(userId, payload) {
        return this.http.put(`${environment.apiUrl}/accounts/profile/${userId}/`, payload)
            .pipe(map(res => {
                return res;
            }));

    }


    getUserProfile(userId) {
        return this.http.get(`${environment.apiUrl}/accounts/profile/${userId}/`)
            .pipe(map(res => {
                return res;
            }));
    }


    changePassword(payload) {
        return this.http.patch(`${environment.apiUrl}/accounts/change_password/`, payload)
            .pipe(map(res => {
                return res;
            }));
    }

    changePasswordOfUserByAdmin(userId, payload) {
        return this.http.patch(`${environment.apiUrl}/accounts/set_password/${userId}/`, payload)
            .pipe(map(res => {
                return res;
            }));
    }

    contactUs(payload: IContactUs) {
        return this.http.post(`${environment.apiUrl}/app/contact_us/`, payload)
            .pipe(map(res => {
                return res;
            }));
    }

    getPoliciesUrl() {
        return this.http.get(`${environment.apiUrl}/app/policies/`)
            .pipe(map(res => {
                return res;
            }));
    }



}
