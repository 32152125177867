import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { MarketTimes } from '../helpers/market-times';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private toastr: ToastrService
  ) { }


  ConfirmedValidator(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    };
  }

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  getSuccessAlert(msg?) {
    this.toastr.success(msg, 'Success!');
  }

  getSuccessAlertWithMessage(msg) {
    if (msg) {
      this.toastr.success(msg);
    }
  }

  getErrorAlert(msg?) {
    this.toastr.error(msg, 'error');
  }

  checkApiResponse(arr) {
    if (typeof arr === 'string' || arr instanceof String) {
      this.getErrorAlert(arr);
    } else if (Array.isArray(arr)) {
      this.getErrorAlert(arr[0]);
    } else if (this.isObject({})) {
      for (const item of Object.values(arr)) {
        this.getErrorAlert(item);
      }
    }
  }

  isObject(o) {
    return o instanceof Object && o.constructor === Object;
  }

  getMarketType(item) {
    // tslint:disable-next-line:max-line-length
    const type = item && item.exchange && item.exchange === 'FOREX' ? 'forex' : item && item.exchange && item.exchange === 'INDEX' ? 'index' : item && item.exchange && item.exchange === 'COMMODITY' ? 'commodities' : item && item.exchange && item.exchange === 'CRYPTO' ? 'crypto' : 'stocks';
    return type;

  }

  getMarketStatus(exchange, data) {
    let isStatus = false;
    let sSec: any;
    let eSec: any;
    let isDay: boolean;
    let exch: any;
    const date = Date.now();
    const d = new Date(date);
    const hh = d.getHours();
    const mm = d.getMinutes();
    const cSec = hh * 60 * 60 + mm * 60;
    const day = d.getDay();

    if (exchange === 'commodities') {
      exch = MarketTimes.filter(ex => data.name.includes(ex.name));
    } else {
      exch = MarketTimes.filter(ex => ex.exchange === data.exchange);
    }
    if (exch && exch.length > 0) {
      for (const item of exch) {
        isDay = item.day.includes(day);
        const sTime = item.time.startTime.split(':');
        sSec = sTime[0] * 60 * 60 + sTime[1] * 60;
        const eTime = item.time.endTime.split(':');
        eSec = eTime[0] * 60 * 60 + eTime[1] * 60;
      }
    }

    switch (exchange) {
      case 'crypto':
        return isStatus = true;
      case 'forex':
        // if (data.symbol === 'USDRUB') {
        //   if (day > 0 && cSec > 43200 && cSec > 0) {
        //     return isStatus = true;
        //   }
        // } else {
        //   if (day > 0 && cSec > 7500 && cSec > 5400) {
        //     return isStatus = true;
        //   }
        // }
        return isStatus = true;
      case 'index':
        return isStatus = true;
      case 'commodities':
        // if (sSec < eSec) {
        //   if (isDay && cSec > sSec && cSec < eSec) {
        //     return isStatus = true;
        //   }

        // } else if (sSec > eSec) {
        //   if (isDay && cSec > sSec && cSec > eSec) {
        //     return isStatus = true;
        //   }
        // }
        return isStatus = true;
      case 'stocks':
        // if (exch.length > 0) {
        //   for (const item of exch) {
        //     const date = Date.now();
        //     const d = new Date(date);
        //     const hh = d.getHours();
        //     const mm = d.getMinutes();
        //     const cSec = hh * 60 * 60 + mm * 60;
        //     const day = d.getDay();
        //     const isDay = item.day.includes(day);

        //     const sTime = item.time.startTime.split(':');
        //     const sSec = sTime[0] * 60 * 60 + sTime[1] * 60;
        //     const eTime = item.time.endTime.split(':');
        //     const eSec = eTime[0] * 60 * 60 + eTime[1] * 60;
        if (sSec < eSec) {
          if (isDay && cSec > sSec && cSec < eSec) {
            return isStatus = true;
          }

        } else if (sSec > eSec) {
          if (isDay && cSec > sSec && cSec > eSec) {
            return isStatus = true;
          }
        }

      //   }
      // }
    }
  }


  validatesOpenTradeChanges(item) {
    if (item.action === 'buy' && item.take_profit_per_unit < item.open_price) {
      this.checkApiResponse('Take profit rate should be greater than open price');
      return true;
    } else if (item.action === 'sell' && item.take_profit_per_unit > item.open_price) {
      this.checkApiResponse('Take profit rate should be lower than open price');
      return true;
    } else if (item.action === 'buy' && item.stop_loss_per_unit > item.open_price) {
      this.checkApiResponse('Stop loss rate should be lower than open price');
      return true;
    } else if (item.action === 'sell' && item.stop_loss_per_unit < item.open_price) {
      this.checkApiResponse('Stop loss rate should be greater than open price');
      return true;
    }

    // After change as per dollar
    // if (item.take_profit < 0) {
    //   this.checkApiResponse('Take profit should be greater than 0');
    //   return true;
    // } else if (item.stop_loss > 0) {
    //   this.checkApiResponse('Stop loss should be lower than 0');
    //   return true;
    // }
  }


  setDecimalPoint(items) {
    if (items) {
      items = items.map(item => {
        const valueStr = String(item.value);
        const openStr = String(item.open_price);
        const profitStr = String(item.take_profit_per_unit);
        const lossStr = String(item.stop_loss_per_unit);
        const plStr = String(item.profit_loss);


        if (item.value && valueStr.includes('.') && valueStr.split('.')[1].length < 2) {
          item.value = item.value.toFixed(2);
        } else if (!valueStr.includes('.')) {
          item.value = item.value.toFixed(2);
        }

        if (item.open_price && openStr.includes('.') && openStr.split('.')[1].length < 2) {
          item.open_price = item.open_price.toFixed(2);
        } else if (!openStr.includes('.')) {
          item.open_price = item.open_price.toFixed(2);
        }

        if (item.take_profit_per_unit && profitStr.includes('.') && profitStr.split('.')[1].length < 2) {
          item.take_profit_per_unit = item.take_profit_per_unit.toFixed(2);
        } else if (!profitStr.includes('.')) {
          item.take_profit_per_unit = item.take_profit_per_unit.toFixed(2);
        }

        if (item.stop_loss_per_unit && lossStr.includes('.') && lossStr.split('.')[1].length < 2) {
          item.stop_loss_per_unit = item.stop_loss_per_unit.toFixed(2);
        } else if (!lossStr.includes('.')) {
          item.stop_loss_per_unit = item.stop_loss_per_unit.toFixed(2);
        }

        if (item.profit_loss && plStr.includes('.') && plStr.split('.')[1].length < 2) {
          item.profit_loss = item.profit_loss.toFixed(2);
        } else if (!plStr.includes('.')) {
          item.profit_loss = item.profit_loss.toFixed(2);
        }
        return item;
      });
      return items;
    }

  }


  getBlockedMarket(marketType, markets) {
    let msg = '';
    if (marketType === 'stocks' && markets.stocks) {
      msg = 'Unable to Open Trade.'
      return msg;
    }

    if (marketType === 'index' && markets.index) {
      msg = 'Unable to Open Trade.'
      return msg;
    }

    if (marketType === 'forex' && markets.forex) {
      msg = 'Unable to Open Trade.'
      return msg;
    }

    if (marketType === 'crypto' && markets.crypto) {
      msg = 'Unable to Open Trade.'
      return msg;
    }

    if (marketType === 'commodities' && markets.commodities) {
      msg = 'Unable to Open Trade.'
      return msg;
    }
  }

  isEmpty = (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
    return true;
  }

  hideSymbol(data) {
    const symbolForHide = ['PAUSD', 'CCUSD']
    const newData = data.filter(item => symbolForHide.indexOf(item.symbol) === -1);
    // {
    //   // if (item.symbol === 'PAUSD') {
    //   //   return false;
    //   // } else if (item.symbol === 'CCUSD') {
    //   //   return false;
    //   // } else {
    //   //   return item;
    //   // }
    // });
    return newData;

  }


  checkDecimalPoint(text) {
    const textStr = String(text);
    if (textStr.includes('.') && textStr.split('.')[1].length > 9) {
      return true;
    } else {
      return false;
    }
  }

  getCollectionSize(data) {
    let size = 0;
    if (data && data.next) {
      let str = data.next;
      if (str.includes('search_query')) {
        size = Number((str.split('page=')[1]).split('&')[0]) * 15
      } else {
        size = Number(str.split('page=')[1]) * 15
      }
      return size;

    } else {
      return data?.result?.length;
    }
  }


}
